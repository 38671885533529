import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'

import { SectionFacaPagamentosDigitais } from './style'
import { IOpenModalComponent } from '../../types'

const FacaPagamentosDigitais = ({ openModal, oneLink, setDataLayer }: IOpenModalComponent) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()

  const dobra10: IDataLayerParams = {
    section: 'dobra_10',
    section_name: 'Faça pagamentos digitais com seu cartão Black Inter',
    element_action: 'click button',
    element_name: 'Solicite seu cartão Black Inter',
  }

  return (
    <SectionFacaPagamentosDigitais id='faca-pagamentos-digitais' className='pb-5 pt-md-5'>
      <div className='container'>
        <div className='row align-items-xl-center'>
          <div className='col-12 col-md-5 offset-md-1 offset-xl-2 order-md-last mb-4'>
            <Img
              fluid={data.bannerFacaPagamentosDigitais.fluid}
              alt='Imagem mostrando a forma de pagamento contactless utilizando um relogio inteligente.'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-white mb-3'>
              Faça pagamentos <span className='d-xl-block'>digitais com seu</span> cartão Black Inter
            </h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-white mb-3'>
              Use o cartão virtual para fazer compras online com segurança e praticidade. Você também pode fazer
              pagamentos usando apenas o celular.
            </p>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-white mb-0'>
              Cadastrando seu cartão Black nas carteiras Apple Pay, Google Pay ou Samsung Pay ou pagar
              com Paypal. Tudo com cashback garantido, é claro.
            </p>
            <div className='mt-4 mt-xl-5'>
              <button
                title='Solicite seu cartão Black Inter'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-none d-md-block'
                onClick={() => {
                  openModal && openModal()
                  setDataLayer(dobra10)
                  sendDatalayerEvent(dobra10)
                }}
              >
                Solicite seu cartão Black Inter
              </button>
              <a
                title='Solicite seu cartão Black Inter'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-md-none'
                href={oneLink}
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_10',
                    section_name: 'Faça pagamentos digitais com seu cartão Black Inter',
                    element_action: 'click button',
                    element_name: 'Solicite seu cartão Black Inter',
                    redirect_url: oneLink,
                  })
                }}
              >
                Solicite seu cartão Black Inter
              </a>
            </div>
          </div>
        </div>
      </div>
    </SectionFacaPagamentosDigitais>
  )
}

export default FacaPagamentosDigitais
