import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const SectionSegurosAIGMastercard = styled.section`
  background-color: ${grayscale[900]};
  @media ${device.desktopXL} {
    background-color: ${grayscale[500]};
  }

  .container {
    @media ${device.desktopXL} {
      background-color: ${grayscale[900]};
      border-radius: 8px;
      padding: 64px 0;
    }
  }

  .btn {
    border-radius: 8px;
    height: 40px;
    @media ${device.tablet} {
      width: 336px !important;
    }
    @media ${device.desktopLG} {
      width: 418px !important;
      max-width: 418px;
    }
  }
`
