import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { SectionViagensInesqueciveis, Link } from './style'

import { IOpenModalComponent } from '../../types'

const ViagensInesqueciveis = ({ openModal, oneLink, setDataLayer }: IOpenModalComponent) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const urlAcesso = 'https://www.prioritypass.com/pt'

  const dobra03: IDataLayerParams = {
    section: 'dobra_03',
    section_name: 'Viagens inesquecíveis com acesso às salas VIP',
    element_action: 'click button',
    element_name: 'Solicite seu cartão Black Inter',
  }

  return (
    <SectionViagensInesqueciveis
      id='viagens-inesqueciveis'
      className='py-5 d-flex align-items-end align-items-lg-center'
    >
      <div className='container'>
        <div className='row flex-md-column justify-content-between align-items-center align-items-lg-start'>
          <div className='col-12 col-lg-6 col-xl-5 mt-4 mt-md-0 mb-md-4'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fw-600 mb-3 text-white'>
              Viagens inesquecíveis com acesso às salas VIP
            </h2>
            <div className='mb-md-3'>
              <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-white pb-md-4'>
                Faça do aeroporto uma parte do seu roteiro e aproveite experiências únicas enquanto aguarda o seu voo.
              </p>
            </div>
            <div className='row mb-md-5 mb-lg-4'>
              <div className='col-md-6 col-lg-12'>
                <p className='fs-14 lh-17 fw-400 text-grayscale--300 mb-2'>
                  Sala VIP Lounge exclusiva nos aeroportos de Guarulhos e Curitiba, que garantem mais conforto e exclusividade enquanto você espera pelo seu voo.
                </p>
                <p className='fs-14 lh-17 fw-400 text-grayscale--300 mb-4'>
                  Aproveite os dois espaços exclusivos para clientes Mastercard com cartão Black Inter. Confira as regras de acesso nesse
                  <Link
                    href={urlAcesso}
                    onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      section_name: 'Viagens inesquecíveis com acesso às salas VIP',
                      element_action: 'click button',
                      element_name: 'link',
                      redirect_url: urlAcesso,
                    })
                    }}
                  > link
                  </Link>.
                </p>
              </div>
              <div className='col-md-6 col-lg-12'>
                <h3 className='fs-16 lh-20 fw-600 text-white mb-2'>Priority Pass Experience</h3>
                <p className='fs-14 lh-17 fw-400 text-grayscale--300 mb-4'>
                  Acesse 1.500 Salas VIP em 600 aeroportos diferentes espalhados pelo mundo todo.
                </p>
              </div>
            </div>
            <div className='d-md-flex justify-content-md-center justify-content-lg-start'>
              <button
                title='Solicite seu cartão Black Inter'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-none d-md-block'
                onClick={() => {
                  openModal && openModal()
                  setDataLayer(dobra03)
                  sendDatalayerEvent(dobra03)
                }}
              >
                Solicite seu cartão Black Inter
              </button>
              <a
                title='Solicite seu cartão Black Inter'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-md-none'
                href={oneLink}
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    section_name: 'Viagens inesquecíveis com acesso às salas VIP',
                    element_action: 'click button',
                    element_name: 'Solicite seu cartão Black Inter',
                    redirect_url: oneLink,
                  })
                }}
              >
                Solicite seu cartão Black Inter
              </a>
            </div>
          </div>
        </div>
      </div>
    </SectionViagensInesqueciveis>
  )
}

export default ViagensInesqueciveis
