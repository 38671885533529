import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import dataJson from './assets/data/data.json'

import { IOpenModalComponent, InsuranceType } from '../../types'
import { SectionSegurosAIGMastercard } from './style'

const SegurosAIGMastercard = ({ openModal, oneLink, setDataLayer }: IOpenModalComponent) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const insuranceData: InsuranceType[] = dataJson

  const dobra07: IDataLayerParams = {
    section: 'dobra_07',
    section_name: 'Seguros AIG Mastercard que combinam com seu estilo de vida',
    element_action: 'click button',
    element_name: 'Solicite seu cartão Black Inter',
  }

  return (
    <SectionSegurosAIGMastercard id='seguros-aig-mastercard' className='py-5'>
      <div className='container'>
        <div className='row align-items-xl-center'>
          <div className='col-12 col-lg-6 col-xl-5 offset-xl-1'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-white mb-3 mb-md-4'>
              Seguros AIG Mastercard que combinam com <span className='d-md-block d-lg-inline'>seu estilo de vida</span>
            </h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-white mb-3 mb-md-5'>
              No Inter, seu cartão Black também te dá acesso a seguros que garantem sua tranquilidade, onde quer que você esteja.
            </p>
            <button
              title='Solicite seu cartão Black Inter'
              className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center mt-3 mt-md-5 d-none d-lg-block'
              onClick={() => {
                openModal && openModal()
                setDataLayer(dobra07)
                sendDatalayerEvent(dobra07)
              }}
            >
              Solicite seu cartão Black Inter
            </button>
          </div>
          <div className='col-12 col-lg-5 offset-lg-1'>
            <div className='row mb-4'>
              {insuranceData.map((insurance: InsuranceType) => (
                <div key={insurance.icon} className='col-md-6 col-lg-12 d-flex align-items-center my-3 my-md-2 mb-xl-4 mt-xl-0'>
                  <div>
                    <OrangeIcon icon={insurance.icon} color='white' size='MD' />
                  </div>
                  <span className='fs-16 lh-20 fw-700 text-white mb-0 ml-3'>{insurance.description}</span>
                </div>
              ))}
            </div>
            <button
              title='Solicite seu cartão Black Inter'
              className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center mt-5 d-none d-md-block d-lg-none'
              onClick={() => {
                openModal && openModal()
                setDataLayer(dobra07)
                sendDatalayerEvent(dobra07)
              }}
            >
              Solicite seu cartão Black Inter
            </button>
            <a
              title='Solicite seu cartão Black Inter'
              className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-md-none'
              href={oneLink}
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_07',
                  section_name: 'Seguros AIG Mastercard que combinam com seu estilo de vida',
                  element_action: 'click button',
                  element_name: 'Solicite seu cartão Black Inter',
                  redirect_url: oneLink,
                })
              }}
            >
              Solicite seu cartão Black Inter
            </a>
          </div>
        </div>
      </div>
    </SectionSegurosAIGMastercard>
  )
}

export default SegurosAIGMastercard
