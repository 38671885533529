import React, { useState } from 'react'
import { Modal } from 'src/components/Modal'
import CartaoBlackOpenAccountRightForm from 'src/components/UI/Forms/CartaoBlackOpenAccountRightForm'
import { ModalAccessAccountRight } from 'src/components/Layout/NewHeader/components/ModalAccessAccountRight/_index'
import useDomReady from 'src/hooks/window/useDomReady'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import {
  CartaoInterBlack,
  CartaoBlackProgramaPontos,
  ViagensInesqueciveis,
  ConhecaOsBeneficiosMastercardBlack,
  ValorizeOQueMaisImporta,
  TenhaUmConciergeMastercard,
  SegurosAIGMastercard,
  VivaExperienciasIncriveis,
  ComoConseguirOCartaoBlack,
  FacaPagamentosDigitais,
  FaqSection,
} from './sections/_index'

import { Wrapper } from './style'

function CartaoBlack () {
  const [ modalOpenYourAccount, setModalOpenYourAccount ] = useState(false)
  const [ openAccessAccount, setOpenAccessAccount ] = useState(false)
  const domReady = useDomReady()
  const oneLink = 'https://intergo.app/73292546'

  const dobra01: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Cartão Black Inter. Exclusividade como você merece!',
    element_action: 'click button',
    element_name: 'Solicite seu cartão Black Inter',
  }

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>(dobra01)

  const openAccountModal = domReady && (
    <Modal isModalOpen={modalOpenYourAccount} setIsModalOpen={setModalOpenYourAccount} locationToRender={document.body}>
      <CartaoBlackOpenAccountRightForm
        closeModal={() => setModalOpenYourAccount(false)}
        dataLayer={dataLayer}
        openAccesAccountModal={setOpenAccessAccount}
        customOrigin='cartao-inter-black'
      />
    </Modal>
  )

  const accessAccountModal = domReady && (
    <Modal isModalOpen={openAccessAccount} setIsModalOpen={setOpenAccessAccount} locationToRender={document.body}>
      <ModalAccessAccountRight closeModal={() => setOpenAccessAccount(false)} />
    </Modal>
  )

  const handleClickOpenModal = () => {
    setModalOpenYourAccount(!modalOpenYourAccount)
  }

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openAccountModal}
        {accessAccountModal}
        <CartaoInterBlack openModal={handleClickOpenModal} oneLink={oneLink} setDataLayer={setDataLayer} dobra01={dobra01} />
        <CartaoBlackProgramaPontos />
        <ViagensInesqueciveis openModal={handleClickOpenModal} oneLink={oneLink} setDataLayer={setDataLayer} />
        <ConhecaOsBeneficiosMastercardBlack openModal={handleClickOpenModal} oneLink={oneLink} setDataLayer={setDataLayer} />
        <ValorizeOQueMaisImporta openModal={handleClickOpenModal} oneLink={oneLink} setDataLayer={setDataLayer} />
        <TenhaUmConciergeMastercard openModal={handleClickOpenModal} oneLink={oneLink} setDataLayer={setDataLayer} />
        <SegurosAIGMastercard openModal={handleClickOpenModal} oneLink={oneLink} setDataLayer={setDataLayer} />
        <VivaExperienciasIncriveis openModal={handleClickOpenModal} oneLink={oneLink} setDataLayer={setDataLayer} />
        <ComoConseguirOCartaoBlack openModal={handleClickOpenModal} oneLink={oneLink} setDataLayer={setDataLayer} />
        <FacaPagamentosDigitais openModal={handleClickOpenModal} oneLink={oneLink} setDataLayer={setDataLayer} />
        <FaqSection faqData={pageContext.structuredData.faq} />
      </Layout>
    </Wrapper>
  )
}

export default CartaoBlack
