import React from 'react'
import usePageQuery from '../../pageQuery'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { SectionTenhaUmConciergeMastercard } from './style'
import { IOpenModalComponent } from '../../types'

const TenhaUmConciergeMastercard = ({ openModal, oneLink, setDataLayer }: IOpenModalComponent) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()

  const dobra06: IDataLayerParams = {
    section: 'dobra_06',
    section_name: 'Tenha um concierge Mastercard à sua disposição, 24 horas por dia',
    element_action: 'click button',
    element_name: 'Solicite seu cartão Black Inter',
  }

  return (
    <SectionTenhaUmConciergeMastercard id='tenha-um-concierge-mastercard' className='container position-relative pt-4 pb-5'>
      <div className='row justify-content-between align-items-center'>
        <div className='col-12 col-md-6'>
          <Img fluid={data.bannerTenhaUmConciergeMastercard.fluid} alt='Ilustração do cartão black de frente com um fundo cinza.' />
        </div>
        <div className='col-12 col-md-6 col-xl-5 pt-4 pt-md-0'>
          <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fw-600 text-white mb-3'>
            Tenha um concierge <span className='d-xl-block'>Mastercard à sua</span> disposição, 24 horas por <span className='d-xl-block'>dia</span>
          </h2>
          <p className='fs-18 lh-22 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 fw-400 text-white'>
            Tenha acesso a um atendimento personalizado com um assistente especial que agenda seus voos, compra presentes, planeja ocasiões especiais e muito mais!
          </p>
          <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-700 text-white mb-4 mb-lg-5'>
            Para sua comodidade, salve em sua agenda:
            <a
              href='tel:08007252025'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  section_name: 'Tenha um concierge Mastercard à sua disposição, 24 horas por dia',
                  element_action: 'click button',
                  element_name: '0800-725-2025',
                  redirect_url: 'tel:08007252025',
                })
              }}
            > 0800-725-2025
            </a>
          </p>
          <button
            title='Solicite seu cartão Black Inter'
            className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-none d-md-block mt-md-5'
            onClick={() => {
              openModal && openModal()
              setDataLayer(dobra06)
              sendDatalayerEvent(dobra06)
            }}
          >
            Solicite seu cartão Black Inter
          </button>
          <a
            title='Solicite seu cartão Black Inter'
            className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-md-none'
            href={oneLink}
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_06',
                section_name: 'Tenha um concierge Mastercard à sua disposição, 24 horas por dia',
                element_action: 'click button',
                element_name: 'Solicite seu cartão Black Inter',
                redirect_url: oneLink,
              })
            }}
          >
            Solicite seu cartão Black Inter
          </a>
        </div>
      </div>
    </SectionTenhaUmConciergeMastercard>
  )
}

export default TenhaUmConciergeMastercard
