import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  @media ${device.tablet} {
    padding: 38px 0 38px 0;
  }
  @media ${device.desktopLG} {
    padding: 64px 0 64px 0;
  }
  @media ${device.desktopXL} {
    padding: 93px 0 93px 0;
  }

  .carousel-content {
    height: 158px;

    @media ${device.desktopLG} {
      height: 211px;
    }
  }
  .react-multi-carousel-track {
    padding: 30px 0 45px;
    @media ${device.desktopXL} {
      margin: 0 0 0 3px;
    }
  }
`
