import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import BgCartao from '../../assets/image/cartao-black-inter.png'

export const CartaoBlackWrapper = styled.section`
  .gatsby-image-wrapper {
    @media ${device.desktopXL}{
      width: 450px;
    }
    @media ${device.desktopXXXL}{
      float: right;
      right: 130px;
    }
  }

  display: flex;
  align-items: center;
  height: auto;
  min-height: 600px;

  > div {
    display:flex;
    flex-direction:column;

    .container-inter-black{
      flex:1;

      @media ${device.desktopLG}{
        margin-top: 80px;
      }
    }
  }
  @media ${device.mobile} {
    background-position-y: 36%;
    background-position-x: right;
  }
  @media ${device.tablet} {
    background: url(${BgCartao}) no-repeat;
    min-height: 500px;
    background-size: 376px;
    background-position-y: 22%;
    background-position-x: right;
  }
  @media ${device.desktopLG}{
    background-size: 450px;
    background-position-x: right;
    background-position-y: 50%;
    min-height: calc(100vh - 73px);
    > div{
      min-height: 100vh;
    }

  }
  @media ${device.desktopXL}{
    background-size: 622px;
    background-position-x: right;
    background-position-y: center;
  }

  @media ${device.desktopXXXL}{
    background-position-x: calc(100% - -100px);
    background-size: 622px;
    background-position-x: right;
    background-position-y: center;
  }

  .btn {
    border-radius: 8px;
    height: 40px;

    @media ${device.desktopLG} {
      min-width: 418px;
    }
  }

  .bread-crumb {
    @media ${device.desktopLG} {
      span {
        font-size: 14px !important;
      }
    }
  }
`
