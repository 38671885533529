import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'
import contornoImagem from '../../assets/image/mask-cartao-inter-black.png'

export const SectionValorizeOQueMaisImporta = styled.section`

  @media ${device.desktopXL}{
    background: url(${contornoImagem}) no-repeat;
    background-position-x: -5%;
    background-position-y: 2%;
  }
  .btn {
    border-radius: 8px;
    height: 40px;

    @media ${device.desktopLG} {
      min-width: 418px;
    }
  }
`

export const ImgDiv = styled.div`
  margin-bottom: 40px;
  
  @media ${device.tablet} {
    margin-bottom: 0;
  }
  .gatsby-image-wrapper  {
    @media ${device.desktopLG} {
      width: 360px;
    }
  }
`

export const Card = styled.div`
  border-radius: 8px;
  background-color: ${grayscale[900]};
  padding: 14px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${device.tablet} {
    padding: 20px;
    min-height: auto;
  }
`
