import React from 'react'

import FAQ from 'src/components/Faq'

// style
import { SectionFaq } from './style'

type answerDataProps = {
  question: string;
  answer: string;
}

type FaqSectionPropsType = {
  faqData: answerDataProps[];
}

const FaqSection = ({ faqData }: FaqSectionPropsType) => {
  return (
    <SectionFaq id='faq' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h3 className='fs-20 lh-25 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-600 text-white'>Perguntas frequentes sobre o cartão de crédito <span className='d-xl-block'>Black Inter</span></h3>
          </div>
          <FAQ
            id='inter-black'
            className='summary-content px-0 text-white'
            columns={{ lg: 1, xl: 1 }}
            answerData={faqData}
            questionColor='text-white'
            answersColor='text-white'
            borderColor='#fff'
            searchBg='#6a6c72'
            placeholderColor='#f6f6fa'
          />
        </div>
      </div>
    </SectionFaq>
  )
}

export default FaqSection
