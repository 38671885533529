import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerSeuCartaoBlackComCashback: imageSharp(fluid: {originalName: { regex: "/banner-seu-cartao-black-cashback-faz-a-diferenca/" }}) {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      cartaoInterBlack: imageSharp(fluid: {originalName: { regex: "/cartao-black-inter/" }}) {
        fluid(maxWidth: 481, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      cartaoBlackSm: imageSharp(fluid: {originalName: { regex: "/cartao-inter-black-sm/" }}) {
        fluid(maxWidth: 481, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerValorizeQueMaisImporta: imageSharp(fluid: {originalName: { regex: "/banner-valorize-o-que-mais-importa/" }}) {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerTenhaUmConciergeMastercard: imageSharp(fluid: {originalName: { regex: "/banner-tenha-um-concierge-mastercard/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      },
      bannerFacaPagamentosDigitais: imageSharp(fluid: {originalName: { regex: "/banner-faca-pagamentos-digitais/" }}) {
        fluid(maxWidth: 453, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
