import React from 'react'
import Link from 'components/GatsbyLinkWrapper/index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import OrangeDS from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import dataJson from './assets/data/data.json'

import { IOpenModalComponent, CardType } from '../../types'
import { SectionComoConseguirOCartaoBlack, Card } from './style'

const ComoConseguirOCartaoBlack = ({ openModal, oneLink, setDataLayer }: IOpenModalComponent) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const cardData: CardType[] = dataJson

  const dobra09: IDataLayerParams = {
    section: 'dobra_09',
    section_name: 'Como conseguir o cartão Black?',
    element_action: 'click button',
    element_name: 'Solicite seu cartão Black Inter',
  }

  return (
    <section>
      <SectionComoConseguirOCartaoBlack id='como-conseguir-cartao-black' className=''>
        <div className='container background-grayscale--900 py-5'>
          <div className='row'>
            <div className='col-12 col-xl-5'>
              <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fw-600 text-white mb-3 text-lg-center text-xl-left'>
                Como conseguir o cartão <span className='d-xl-block'>Black?</span>
              </h2>
              <p className='fs-16 lh-20 fs-md-18 lh-md-22 fw-400 text-white mb-4 mb-lg-5 mb-xl-3 text-lg-center text-xl-left'>
                Existem várias formas de conseguir um cartão Black no Inter. Escolha a que mais combina com você:
              </p>
              <div className='row pt-lg-4 pt-xl-0'>
                {cardData.map((data: CardType, index: number) => (
                  <Card key={index} className='col-12 col-lg-6 col-xl-12 d-flex mb-3 mb-lg-4'>
                    <div>
                      <OrangeDS size='MD' color='#fff' icon={data.icon} />
                    </div>
                    {data.hasLink !== ''
                    ? <h4
                        className='fs-16 lh-20 fw-700 text-white ml-3 mb-0'
                        dangerouslySetInnerHTML={{ __html: data.description }}
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_09',
                            section_name: 'Como conseguir o cartão Black?',
                            element_action: 'click button',
                            element_name: data.hasLink,
                            redirect_url: data.url,
                          })
                        }}
                      />
                  : <h4
                      className='fs-16 lh-20 fw-700 text-white ml-3 mb-0'
                      dangerouslySetInnerHTML={{ __html: data.description }}
                    />
                  }
                  </Card>
              ))}
              </div>
              <button
                title='Solicite seu cartão Black Inter'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center mt-4 d-none d-md-block d-lg-none'
                onClick={() => {
                  openModal && openModal()
                  setDataLayer(dobra09)
                  sendDatalayerEvent(dobra09)
                }}
              >
                Solicite seu cartão Black Inter
              </button>
              <a
                title='Solicite seu cartão Black Inter'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-md-none mt-4'
                href={oneLink}
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_09',
                    section_name: 'Como conseguir o cartão Black?',
                    element_action: 'click button',
                    element_name: 'cliente Inter Black',
                    redirect_url: oneLink,
                  })
                }}
              >
                Solicite seu cartão Black Inter
              </a>
            </div>
            <div className='col-12 col-lg-10 col-xl-5 offset-lg-1 offset-xl-2 d-none d-xl-block mt-xl-5'>
              <h2 className='fs-20 lh-25 fs-md-24 lh-md-30 fw-600 text-white mb-3 mb-md-4'>
                Com investimentos a partir de R$250 mil, você também se torna um
                <Link
                  to='/pra-voce/relacionamento/inter-black/'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_09',
                      section_name: 'Como conseguir o cartão Black?',
                      element_action: 'click button',
                      element_name: 'cliente Inter Black',
                      redirect_url: window.location.href + '/pra-voce/relacionamento/inter-black/',
                    })
                  }}
                > cliente Inter Black
                </Link> e passa a ter um advisor exclusivo para te ajudar a criar uma carteira cada vez mais robusta e personalizada.
              </h2>
              <p className='fs-12 lh-15 fw-400 text-white mb-lg-5'>Para ter o cartão Black pelo valor das faturas, é
                preciso estar em dia com o pagamento delas, sem nenhum atraso. Além disso, o limite (acima de R$ 7 mil)
                precisa ter sido concedido pelo Inter na análise de crédito, sem contar o CDB Mais Limite.
              </p>
              <div className='d-none d-lg-flex justify-content-lg-center justify-content-xl-start'>
                <button
                  title='Solicite seu cartão Black Inter'
                  className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
                  onClick={() => {
                    openModal && openModal()
                    setDataLayer(dobra09)
                    sendDatalayerEvent(dobra09)
                  }}
                >
                  Solicite seu cartão Black Inter
                </button>
              </div>
            </div>
          </div>
        </div>
      </SectionComoConseguirOCartaoBlack>
      <section className='d-xl-none'>
        <div className='container py-5'>
          <div className='row'>
            <div className='col-12 col-lg-10 col-xl-5 offset-lg-1 offset-xl-2'>
              <h2 className='fs-20 lh-25 fs-md-24 lh-md-30 fw-600 text-white mb-3 mb-md-4'>
                Com investimentos a partir de R$250 mil, você também se torna um
                <Link
                  to='/pra-voce/relacionamento/inter-black/'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_09',
                      section_name: 'Como conseguir o cartão Black?',
                      element_action: 'click button',
                      element_name: 'cliente Inter Black',
                      redirect_url: window.location.href + '/pra-voce/relacionamento/inter-black/',
                    })
                  }}
                > cliente Inter Black
                </Link> e passa a ter um advisor exclusivo para te ajudar a criar uma carteira cada vez mais robusta e personalizada.
              </h2>
              <p className='fs-12 lh-15 fw-400 text-white mb-lg-5'>
                Para ter o cartão Black pelo valor das faturas, é preciso estar em dia com o pagamento delas, sem
                nenhum atraso. Além disso, o limite (acima de R$ 7 mil) precisa ter sido concedido pelo Inter na
                análise de crédito, sem contar o CDB Mais Limite.
              </p>
              <div className='d-none d-lg-flex justify-content-lg-center'>
                <button
                  title='Solicite seu cartão Black Inter'
                  className='btn btn-orange--extra btn--lg rounded-2 text-none d-none d-md-block text-md-center'
                  onClick={() => {
                    openModal && openModal()
                    setDataLayer(dobra09)
                    sendDatalayerEvent(dobra09)
                  }}
                >
                  Solicite seu cartão Black Inter
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

export default ComoConseguirOCartaoBlack
