import React, { useState, useEffect, MouseEvent, ChangeEvent } from 'react'
import { sendDataLayerEvent, getParameterByName, sendCDPFormData } from 'src/shared/helpers'
import { differenceInYears } from 'date-fns'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'

import MSG from './msgAccount.json'
import useWidth from 'src/hooks/window/useWidth'
import AcceptTerms from 'src/components/AcceptTerms/OpenAccount'

import errorImg from 'src/assets/images/shared/erro-pig.png'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import BaixeOApp from 'src/assets/images/qrcode-header.jpg'
import qrCodeSoliciteInterBlack from './assets/images/qr_code_lp_cartao_black.png'
import qrCodeAbraUmaContaGratuita from './assets/images/qr-code-abra-uma-conta-gratuita.png'
import sucesso from '../images/sucesso.png'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { Container, CloseButton } from './style'
import { apiForm } from 'src/config/api/axiosRequest'

type CartaoBlackOpenAccountRightForm = {
  closeModal: () => void;
  openAccesAccountModal: (state: boolean) => void;
  customOrigin?: string;
  dataLayer: IDataLayerParams;
}
interface IFormValues {
  nome: string;
  email: string;
  celular: string;
  cpf: string;
  dataNascimento: string;
}

function CartaoBlackOpenAccountRightForm ({ closeModal, openAccesAccountModal, customOrigin, dataLayer }: CartaoBlackOpenAccountRightForm) {
  const [ sendDatalayerEvent ] = useDataLayer()
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const WIDTH_MD = 1024
  const windowWidth = useWidth(200)
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ underAge, setUnderAge ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ filled, setFilled ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ haveAccount, setHaveAccount ] = useState(false)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)

  const urlMobile = 'https://intergo.app/7820e737'
  const urlDesktop = '/pra-voce/conta-digital/conta-kids/'
  const urlUnderAge = windowWidth < WIDTH_MD ? urlMobile : urlDesktop

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
  }, [])

  const sendForm = async (data: IFormValues) => {
    setLoading(true)
    const newDate = data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join(',')
    const result = differenceInYears(new Date(), new Date(newDate))

    const formData = {
      ...data,
      cpf: data.cpf.replace(/\D/g, ''),
      celular: data.celular.replace(/\D/g, ''),
      dataNascimento: data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join('-'),
      termoAceito: accept,
      origin: customOrigin || 'site-institucional',
      utmCampaign: utmCampaign?.toString(),
      utmMedium: utmMedium?.toString(),
      utmSource: utmSource?.toString(),
      utmContent: utmContent?.toString(),
    }

    try {
      if (result < 18) {
        setUnderAge(true)
        sendDatalayerEvent({
          ...dataLayer,
          section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
          section_name: dataLayer ? dataLayer.section_name : '',
          element_name: 'Continuar',
          element_action: 'submit',
          element_previous: dataLayer ? `${dataLayer.element_name}, ${dataLayer.section}` : 'Não tenho conta',
        })
      } else {
        await apiForm.put(`${URLS.OPEN_ACCOUNT_V2}`, formData)
        sendCDPFormData({ formName: 'Formulario abertura de conta', cpf: formData.cpf, email: formData.email })
        setSent(true)
        sendDataLayerEvent('form_submitted')
        sendDatalayerEvent({
          ...dataLayer,
          section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
          section_name: dataLayer ? dataLayer.section_name : '',
          element_name: 'Continuar',
          element_action: 'submit',
          element_previous: dataLayer ? `${dataLayer.element_name}, ${dataLayer.section}` : 'Não tenho conta',
          step: 'success',
        })
      }
    } catch (e) {
      setError(true)
      setLoading(false)
      setFilled(false)
      sendDatalayerEvent({
        ...dataLayer,
        section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
        section_name: dataLayer ? dataLayer.section_name : '',
        element_name: 'Continuar',
        element_action: 'submit',
        element_previous: dataLayer ? `${dataLayer.element_name}, ${dataLayer.section}` : 'Não tenho conta',
      })
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
    setFilled(false)
  }

  const asUnderAge = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    window.location.href = urlUnderAge
  }

  const handleOpenAccessAccount = () => {
    closeModal()
    openAccesAccountModal(true)
  }

  const closeButton = closeModal && (
    <CloseButton className='border-0 bg-transparent pt-4' onClick={closeModal}>
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  return (
    <>
      { error && (
        <Container isModal={closeModal} className='d-flex align-items-center'>
          {closeButton}
          <div className='col-12 text-center'>
            <h3 className='fs-20 lh-24 mb-5'>Ah não! Ocorreu um erro com a sua solicitação...</h3>
            <img src={errorImg} alt='Imagem de erro' />
            <p className='text-grayscale--400 fs-18 lh-22 pt-3'>
              Verifique a sua conexão com a internet e tente novamente. <br />
              <button
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  handleReturn(e)
                  sendDatalayerEvent({
                    ...dataLayer,
                    section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
                    element_name: 'Clique aqui',
                    element_previous: dataLayer ? dataLayer.element_name + ' ' + dataLayer.section : 'Não tenho conta',
                  })
                }}
                title='Clique aqui para voltar'
                className='bg-transparent border-0 fw-600'
              >
                Clique aqui
              </button>
              para voltar.
            </p>
          </div>
        </Container>
      )}
      { sent && (
        <Container isModal={closeModal} className='d-flex haveAccount'>
          {closeButton}
          <div className='col-10 d-flex align-items-center'>
            <div>
              <h3 className='fs-20 lh-24 fw-600 text-grayscale--500'>
                Baixe o app e <span className='d-block text-orange--extra'>abra uma conta gratuita</span>
              </h3>
              <p className='fs-14 lh-17 text-grayscale--400 mb-4'>
                {MSG.sent}
              </p>
              <div>
                <img src={qrCodeAbraUmaContaGratuita} alt='Baixe o App Inter' />
              </div>
              <div className='pt-5'>
                <span className='fs-14 lh-18 text-grayscale--400'>
                  Já é cliente Inter?
                  <button
                    onClick={() => {
                      setHaveAccount(true)
                      sendDatalayerEvent({
                        ...dataLayer,
                        section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
                        section_name: dataLayer ? dataLayer.section_name : '',
                        element_name: 'Clique aqui',
                        element_previous: dataLayer ? dataLayer.element_name + ' ' + dataLayer.section : 'Não tenho conta',
                      })
                    }}
                    className='text-orange--extra fw-600 bg-transparent border-0'
                  > Clique aqui
                  </button>
                </span>
              </div>
            </div>
          </div>
        </Container>
      )}
      { underAge && (
        <Container isModal={closeModal} className='d-flex align-items-center under-age'>
          {closeButton}
          <div className='col-12 text-md-center text-lg-left'>
            <h3 className='fs-16 lh-20 text-grayscale--400 fw-400 fw-md-600'>
              Vimos que você tem menos de 18 anos.
            </h3>
            <p className='fs-20 lh-23 fs-md-24 lh-md-28 text-grayscale--500 mb-4 fw-600'>
              <span className='d-block'>
                Conheça nossa
                <span
                  className='text-orange--extra cursor-pointer'
                  onClick={(evt: MouseEvent<HTMLButtonElement>) => {
                    asUnderAge(evt)
                    sendDatalayerEvent({
                      ...dataLayer,
                      section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
                      element_name: 'Conta Kids',
                      element_previous: dataLayer ? dataLayer.element_name + ' ' + dataLayer.section : 'Não tenho conta',
                      redirect_url: urlUnderAge,
                    })
                  }}
                > Conta Kids
                </span>,
              </span>
              que também é completa, digital e gratuita.
            </p>
            <div className='col-12 pb-2 mx-auto d-lg-none text-center'>
              <img src={sucesso} alt='Sucesso' />
            </div>
            <div className='col-12 pb-2 mx-auto d-none d-lg-block text-center'>
              <img src={BaixeOApp} alt='Baixe o App Inter' />
            </div>
            <div className='col-12 d-none d-lg-block px-0'>
              <hr />
              <p className='fs-14 lh-17 text-left'>
                Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter e
                clicar em “Abrir conta completa e gratuita”. É rápido e fácil!
              </p>
              <p className='fs-14 lh-17 text-left'>
                De acordo com nossa Política de Privacidade, não armazenamos dados de menores de 18 anos
                sem o consentimento dos responsáveis.
              </p>
            </div>
          </div>
        </Container>
      )}
      {filled && (
        <Container isModal={closeModal} className='d-flex align-items-center filled'>
          {closeButton}
          <div className='col-12 text-center'>
            <h3 className='fs-20 lh-24 fw-600 text-grayscale--500'>
              Baixe o app e vá em <br /> &quot;Abrir uma conta gratuita&quot;
            </h3>
            <p className='fs-16 lh-20 text-grayscale--400 mb-5'>
              {MSG.filled}
            </p>
            <div className='col-12'>
              <img src={BaixeOApp} alt='Baixe o App Inter' />
            </div>
          </div>
        </Container>
      )}
      {haveAccount && (
        <Container isModal={closeModal} className='d-flex haveAccount'>
          {closeButton}
          <div className='col-10 d-flex align-items-center'>
            <div>
              <h3 className='fs-20 lh-24 fw-600 text-grayscale--500'>
                Solicite seu o seu <span className='d-block text-orange--extra'>cartão Inter Black</span>
              </h3>
              <p className='fs-14 lh-17 text-grayscale--400 mb-4'>
                {MSG.haveAccount}
              </p>
              <div>
                <img src={qrCodeSoliciteInterBlack} alt='Baixe o App Inter' />
              </div>
              <div className='pt-4'>
                <span className='fs-14 lh-18 text-grayscale--400'>
                  Ir para internet banking.
                  <button
                    onClick={() => {
                      handleOpenAccessAccount()
                      sendDatalayerEvent({
                        ...dataLayer,
                        section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
                        section_name: dataLayer ? dataLayer.section_name : '',
                        element_name: 'Acessar',
                        element_previous: dataLayer ? dataLayer.element_name + ' ' + dataLayer.section : 'Não tenho conta',
                      })
                    }}
                    className='text-orange--extra fw-600 bg-transparent border-0'
                  >
                    Acessar
                  </button>
                </span>
              </div>
            </div>
          </div>
        </Container>
      )}
      <Container isModal={closeModal} className={`${error || sent || filled || underAge || haveAccount ? 'd-none' : 'd-flex'} align-items-center`}>
        {closeButton}
        <div className='row'>
          <div className='col-12 text-center py-4 pt-lg-0'>
            <h2 className='fs-20 text-left text-md-center mb-2 lh-24 mt-3 text-grayscale--500'>
              Abra agora sua Conta Digital
            </h2>
          </div>
          <div className='col-12'>
            <form className='form--default' name='open_account' onSubmit={handleSubmit(sendForm)}>
              <div className='body-form'>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='nome' className='fs-12 lh-15 fw-600'>Nome</label>
                  <input
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                    name='nome'
                    id='nome'
                    type='text'
                    maxLength={100}
                    placeholder='Digite seu nome'
                  />
                  {errors.nome && <p className='fs-12 text-red--base mb-0 text-right'>{errors.nome.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='celular' className='fs-12 lh-15 fw-600'>Telefone</label>
                  <input
                    ref={register({
                      required: 'Digite um telefone válido',
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: 'Telefone inválido',
                      },
                    })}
                    name='celular'
                    id='celular'
                    type='tel'
                    placeholder='Digite seu telefone'
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('celular', Masks.MaskPHONE(event.currentTarget.value))}
                  />
                  {errors.celular && <p className='fs-12 text-red--base mb-0 text-right'>{errors.celular.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='email' className='fs-12 lh-15 fw-600'>E-mail</label>
                  <input
                    ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name='email'
                    id='email'
                    type='text'
                    placeholder='Digite seu e-mail'
                  />
                  {errors.email && <p className='fs-12 text-red--base mb-0 text-right'>{errors.email.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='cpf' className='fs-12 lh-15 fw-600'>CPF</label>
                  <input
                    ref={register({
                      required: 'Digite um CPF válido',
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                      },
                    })}
                    name='cpf'
                    id='cpf'
                    type='tel'
                    placeholder='Digite seu CPF'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                  />
                  {errors.cpf && <p className='fs-12 text-red--base mb-0 text-right'>{errors.cpf.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 pb-1'>
                  <label htmlFor='dataNascimento' className='fs-12 lh-15 fw-600'>Data de Nascimento</label>
                  <input
                    ref={register({
                      required: 'Digite uma data válida',
                      validate: {
                        isDate: (value: string) => Validations.dateValid(value) || 'Data Inválida',
                      },
                    })}
                    name='dataNascimento'
                    id='dataNascimento'
                    type='tel'
                    placeholder='dd/mm/aaaa'
                    maxLength={10}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('dataNascimento', Masks.MaskDATE(event.currentTarget.value))}
                  />
                  {errors.dataNascimento && <p className='fs-12 text-red--base mb-0 text-right'>{errors.dataNascimento.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3'>
                  <AcceptTerms accept={accept} setAccept={setAccept} name='conta-digital-pf' dataLayer={dataLayer} />
                </div>
                <div className='col-12 text-center'>
                  <button
                    type='submit' title='Enviar contato' disabled={!accept || loading}
                    className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                  >
                    {loading ? 'Enviando...' : 'Continuar'}
                  </button>
                </div>
              </div>
            </form>
            <div className='text-center pt-3'>
              <span className='fs-14 lh-18 text-grayscale--400'>
                Já é cliente Inter?
                <button
                  onClick={() => {
                    setHaveAccount(true)
                    sendDatalayerEvent({
                      ...dataLayer,
                      section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
                      section_name: dataLayer ? dataLayer.section_name : '',
                      element_name: 'Clique aqui',
                      element_previous: dataLayer ? dataLayer.element_name + ' ' + dataLayer.section : 'Não tenho conta',
                    })
                  }}
                  className='text-orange--extra fw-600 bg-transparent border-0'
                > Clique aqui
                </button>
              </span>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default CartaoBlackOpenAccountRightForm
