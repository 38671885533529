import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import dataJson from './assets/data/data.json'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { IOpenModalComponent } from '../../types'
import { SectionVivaExperienciasIncriveis, Card, CardsDiv } from './style'

type cardType = {
  title: string;
  description: string;
}

const VivaExperienciasIncriveis = ({ openModal, oneLink, setDataLayer }: IOpenModalComponent) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const cardData: cardType[] = dataJson

  const dobra08: IDataLayerParams = {
    section: 'dobra_08',
    section_name: 'Viva experiências incríveis com o cartão Black sem anuidade do Inter',
    element_action: 'click button',
    element_name: 'Solicite seu cartão Black Inter',
  }

  return (
    <SectionVivaExperienciasIncriveis id='viva-experiencias-incriveis' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-6 col-xl-5 pr-lg-0'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-white mb-3'>
              Viva experiências <span className='d-xl-block'>incríveis com o cartão</span> Black sem anuidade <span className='d-xl-block'>do Inter</span>
            </h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-white mb-4 mb-lg-5'>Tenha momentos muito mais especiais e aproveite todas as vantagens que só o cartão Inter Black pode proporcionar.</p>
            <button
              title='Solicite seu cartão Black Inter'
              className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center mt-3 d-none d-lg-block'
              onClick={() => {
                openModal && openModal()
                setDataLayer(dobra08)
                sendDatalayerEvent(dobra08)
              }}
            >
              Solicite seu cartão Black Inter
            </button>
          </div>
          <div className='col-12 col-lg-5 offset-lg-1 offset-xl-2'>
            <CardsDiv>
              {cardData.map((card: cardType) => (
                <Card key={card.title} className='p-3 mb-3'>
                  <h4 className='fs-16 lh-20 fw-600 text-white mb-1'>{card.title}</h4>
                  <p className='fs-14 lh-17 fw-400 text-white mb-0'>{card.description}</p>
                </Card>
            ))}
            </CardsDiv>
            <button
              title='Solicite seu cartão Black Inter'
              className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-none d-md-block d-lg-none mt-md-4'
              onClick={() => {
                openModal && openModal()
                setDataLayer(dobra08)
                sendDatalayerEvent(dobra08)
              }}
            >
              Solicite seu cartão Black Inter
            </button>
            <a
              title='Solicite seu cartão Black Inter'
              className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-md-none mt-2'
              href={oneLink}
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_08',
                  section_name: 'Viva experiências incríveis com o cartão Black sem anuidade do Inter',
                  element_action: 'click button',
                  element_name: 'Solicite seu cartão Black Inter',
                  redirect_url: oneLink,
                })
              }}
            >
              Solicite seu cartão Black Inter
            </a>
          </div>
        </div>
      </div>
    </SectionVivaExperienciasIncriveis>
  )
}

export default VivaExperienciasIncriveis
