import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const SectionVivaExperienciasIncriveis = styled.section`
  @media ${device.desktopLG} {
    padding-top: 96px !important;
    padding-bottom: 96px !important;
  }

  .btn {
    border-radius: 8px;
    height: 40px;
    @media ${device.tablet} {
      width: 334px !important;
    }
    @media ${device.desktopLG} {
      width: 418px !important;
      max-width: 418px;
    }
  }
`

export const Card = styled.div`
  background-color: ${grayscale[900]};
  border-radius: 8px;
`

export const CardsDiv = styled.div`

`
