import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const SectionFacaPagamentosDigitais = styled.section`
  @media ${device.desktopXL} {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .btn {
    border-radius: 8px;
    height: 40px;
    @media ${device.tablet} {
      width: 334px !important;
    }
    @media ${device.desktopLG} {
      width: 418px !important;
      max-width: 418px;
    }
  }

  .gatsby-image-wrapper {
    @media ${device.desktopXL} {
      width: 452px;
      float: right;
    }
  }
`
