import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'

export const Wrapper = styled.div`
  background: ${grayscale[500]};

  h1, h2, h3, h4, h5 {
    font-family: 'Sora';
  }
`
