import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import OrangeDS from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import CarouselJson from './assets/data/data.json'

import { Section } from './style'

type CarouselData = {
  icon: string;
  title: string;
}

const CartaoBlackProgramaPontos = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 fs-lg-32 lh-30 lh-lg-50 fw-600 text-white mb-3 mb-md-4 text-md-center'>
              Cartão Black com programa de pontos
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-20 lh-xl-22 text-md-center fw-400 text-white'>
              Com o Inter Loop, você ganha 1 ponto a cada R$ 2,50 em compras com o seu cartão de crédito Inter para trocar por benefícios exclusivos.
            </p>
            <p className='fs-10 fs-md-12 fs-xl-14 lh-12 lh-md-14 lh-xl-16 text-grayscale--200 fw-400 text-md-center'>
              Para ganhar pontos, é necessário ter o Débito Automático de fatura ativo.
            </p>
          </div>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 2, partialVisibilityGutter: 30 }}
              md={{ items: 5 }}
              lg={{ items: 5 }}
              xl={{ items: 5 }}
            >
              {
                CarouselJson.map((item: CarouselData, index: number) => (
                  <div key={index} className='carousel-content rounded-3 border pl-3 mr-3 pt-lg-1'>
                    <OrangeDS size='MD' color='#ffffff' icon={item.icon} className='mt-4' />
                    <h4 className='fs-14 fs-md-16 fs-lg-20 lh-16 lh-md-20 lh-lg-25 text-white fw-600 mt-3 mt-lg-4' dangerouslySetInnerHTML={{ __html: item.title }} />
                  </div>
                ))
              }
            </DefaultCarousel>
            <div className='text-center'>
              <Link
                title='Conhecer o Inter Loop'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-center mt-5 mt-md-1'
                to='/pra-voce/cartoes/programa-de-pontos'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    element_name: 'Conhecer o Inter Loop',
                    section_name: 'Cartão Black com programa de pontos',
                    redirect_url: 'http://inter.co/pra-voce/cartoes/programa-de-pontos',
                  })
                }}
              >
                Conhecer o Inter Loop
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CartaoBlackProgramaPontos
