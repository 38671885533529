import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const SectionComoConseguirOCartaoBlack = styled.section`
  background-color: ${grayscale[900]};

  .btn {
    border-radius: 8px;
    height: 40px;
    @media ${device.tablet} {
      width: 334px !important;
    }
    @media ${device.desktopLG} {
      width: 418px !important;
      max-width: 418px;
    }
  }
`

export const Card = styled.div`
  a {
    text-decoration: underline;
  }
`
