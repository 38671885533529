import styled from 'styled-components'
import BackgroundViagensInesqueciveisSM from '../../assets/image/background-viagens-inesqueciveis-sm.png'
import BackgroundViagensInesqueciveisMD from '../../assets/image/background-viagens-inesqueciveis-md.png'
import BackgroundViagensInesqueciveisLG from '../../assets/image/background-viagens-inesqueciveis-lg.png'
import BackgroundViagensInesqueciveisXL from '../../assets/image/background-viagens-inesqueciveis-xl.png'

import { device } from 'src/styles/breakpoints'

export const SectionViagensInesqueciveis = styled.section`
  background: url(${BackgroundViagensInesqueciveisSM}) no-repeat;
  min-height: 885px;
  background-size: 152%;
  background-position-y: 0;
  background-position-x: 2px;
  @media ${device.tablet} {
    background: url(${BackgroundViagensInesqueciveisMD}) no-repeat;
    min-height: 795px;
  }
  @media ${device.desktopLG} {
    background: url(${BackgroundViagensInesqueciveisLG}) no-repeat right;
    min-height: 650px;
    background-color: #212121;
  }
  @media ${device.desktopXL} {
    background: url(${BackgroundViagensInesqueciveisXL}) no-repeat right;
    min-height: 700px;
    background-color: #212121;
  }

  .btn {
    border-radius: 8px;
    height: 40px;
    @media ${device.tablet} {
      max-width: 336px;
    }
    @media ${device.desktopLG} {
      max-width: 418px;
    }
  }
`
export const Link = styled.a`
  color: #B6B7BB;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`
