import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const SectionTenhaUmConciergeMastercard = styled.section`
  .btn {
    border-radius: 8px;
    height: 40px;
    @media ${device.desktopLG} {
      min-width: 418px;
    }
  }


`
