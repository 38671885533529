import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'

export const SectionFaq = styled.section`
  background: ${grayscale['500']};
  .summary {
    h4 {
      color: #ffffff !important;
    }
    .arrow {
      width: 32px !important;
      height: 32px !important;
      background-color: #FFF2E7 !important;
      border-radius: 50% !important;
      background-position: center !important;
    }
  }
  .summary-content {
    padding-right: 0 !important;

    .col-1 {
      padding: 0 !important;
    }
  }
  .search-input {
    text-align: left;
    background: #6A6C72 !important;
    color: #F5F6FA !important;

    ::placeholder {
      color: #F5F6FA !important;
    }
  }
  .icon {
    position: absolute;
    right: 22px;
  }
`
