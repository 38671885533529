import Img from 'gatsby-image'
import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import usePageQuery from '../../pageQuery'
import dataJson from './assets/data/data.json'

import { IOpenModalComponent } from '../../types'
import { Card, ImgDiv, SectionValorizeOQueMaisImporta } from './style'

type CardType = {
  title: string;
  description: string;
}

const ValorizeOQueMaisImporta = ({ openModal, oneLink, setDataLayer }: IOpenModalComponent) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()
  const cardsData: CardType[] = dataJson

  const dobra05: IDataLayerParams = {
    section: 'dobra_05',
    section_name: 'Valorize o que mais importa: você!',
    element_action: 'click button',
    element_name: 'Solicite seu cartão Black Inter',
  }

  return (
    <SectionValorizeOQueMaisImporta id='valorize-o-que-mais-importa' className='position-relative'>
      <div className='container'>
        <div className='row my-5 justify-content-center align-items-md-center'>
          <ImgDiv className='col-10 col-md-5 col-xl-4 offset-xl-2'>
            <Img fluid={data.bannerValorizeQueMaisImporta.fluid} alt='Mulher sentada com uma pessoa lhe servindo um champanhe em uma taça de vidro sobre a mesa.' />
          </ImgDiv>
          <div className='col-12 col-md-6 col-xl-5 offset-md-1 mb-4 mb-md-0'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 mb-3 text-white'>
              Valorize o que mais importa: você!
            </h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-white mb-4'>
              Conte com serviços exclusivos para cuidar da sua saúde e receba descontos em hotéis, pacotes de viagens, aluguéis de carros, atividades e muito mais.
            </p>
            <div className='pt-md-3 pb-md-0'>
              <button
                title='Solicite seu cartão Black Inter'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-none d-md-block'
                onClick={() => {
                  openModal && openModal()
                  setDataLayer(dobra05)
                  sendDatalayerEvent(dobra05)
                }}
              >
                Solicite seu cartão Black Inter
              </button>
              <a
                title='Solicite seu cartão Black Inter'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-md-none'
                href={oneLink}
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: 'Valorize o que mais importa: você!',
                    element_action: 'click button',
                    element_name: 'Solicite seu cartão Black Inter',
                    redirect_url: oneLink,
                  })
                }}
              >
                Solicite seu cartão Black Inter
              </a>
            </div>
          </div>
          <div className='col-12 mt-2 mt-md-5'>
            <div className='row pt-lg-4'>
              {cardsData.map((card: CardType) => (
                <div key={card.title} className='col-12 my-2 my-md-0 px-md-2'>
                  <Card>
                    <h4 className='fs-16 lh-20 fw-600 text-white mb-2'>{card.title}</h4>
                    <p className='fs-14 lh-17 fw-400 text-white mb-0'>{card.description}</p>
                  </Card>
                </div>
            ))}
            </div>
          </div>
        </div>
      </div>
    </SectionValorizeOQueMaisImporta>
  )
}

export default ValorizeOQueMaisImporta
