import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { widths } from 'src/styles/breakpoints'

import BreadCrumb from 'src/components/Breadcrumb'
import ScrollTo from 'src/components/ScrollTo'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { IDataLayerCartaoBlack } from '../../types'

import { CartaoBlackWrapper } from './style'
import useWidth from 'src/hooks/window/useWidth'

const CartaoInterBlack = ({ openModal, oneLink, dobra01, setDataLayer }: IDataLayerCartaoBlack) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()
  const width = useWidth(300)

  return (
    <CartaoBlackWrapper>
      <div id='cartao-black' className='container position-relative'>
        <div className='container-inter-black row align-items-center'>
          <div className='col-12 col-md-6 col-xl-5 flex-grow-1 pr-md-0'>
            {
              width < widths.md && (
                <div className='mb-3 mt-3'>
                  <Img fluid={data.cartaoBlackSm.fluid} />
                </div>
              )
            }
            <div className='d-none d-md-block mb-4'>
              <BreadCrumb
                sectionName='Cartão Black Inter. Exclusividade como você merece!'
                type='Pra você'
                product='Cartão de débito e crédito'
                text='Cartão Inter Black'
                link='/pra-voce/cartao-inter-black/'
              />
            </div>
            <h1 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-600 text-white mb-3'>
              Cartão Black Inter. <span className='d-md-block d-lg-inline'>Exclusividade</span> como <span className='d-xl-block'>você merece!</span>
            </h1>
            <p className='fs-16 lh-20 fs-md-18 lh-md-22 text-white'>
              Acesse Salas VIP em aeroportos, tenha um assistente especial à sua disposição e viva experiências exclusivas.
            </p>
            <div className='pt-md-3 pb-md-4 pb-xl-5'>
              <button
                title='Solicite seu cartão Black Inter'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-none d-md-block'
                onClick={() => {
                  openModal && openModal()
                  setDataLayer(dobra01)
                  sendDatalayerEvent(dobra01)
                }}
              >
                Solicite seu cartão Black Inter
              </button>
            </div>
            <div className='row d-md-flex mt-4 d-flex align-items-center'>
              <div className='col-6 d-flex align-items-center'>
                <OrangeIcon className='mr-2' size='MD' icon='cashback' color='#00AA4F' />
                <p className='text-green--dark fs-12 fs-md-16 lh-md-20 fs-lg-20 lh-16 lh-lg-25 fw-600 mb-0 pr-md-0'>
                  <span className='d-block'>Programa de</span> pontos gratuito
                </p>
              </div>
              <div className='col-6'>
                <p className='text-white fs-12 fs-md-16 lh-md-20 fs-lg-20 lh-lg-25 fw-600 mb-0'>
                  <OrangeIcon className='mr-2' size='MD' icon='no-taxes' color='white' />Zero anuidade
                </p>
              </div>
            </div>
            <a title='Solicite seu cartão Black Inter' className='btn btn-orange--extra btn--lg rounded-2 text-none mt-4 text-md-center d-md-none' href={oneLink}>
              Solicite seu cartão Black Inter
            </a>
          </div>
        </div>
        <div className='row justify-content-center align-items-center mb-md-4'>
          <ScrollTo
            to='#conheca-os-beneficios'
            sectionName='Cartão Black Inter. Exclusividade como você merece!'
            section='dobra_01'
            elementName='Conheça os benefícios'
            styles='scroll-link text-center mt-4 mt-lg-0'
          >
            <p className='d-none d-md-block fs-18 fw-400 text-orange--medium m-0'>
              Conheça os benefícios
            </p>
            <OrangeIcon
              color='#EB7000'
              size='MD'
              icon='chevron-down'
            />
          </ScrollTo>
        </div>
      </div>
    </CartaoBlackWrapper>
  )
}

export default CartaoInterBlack
