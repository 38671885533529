import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const SectionConhecaOsBeneficiosMastercardBlack = styled.section`
  @media ${device.desktopLG} {
    padding-top: 114px;
    padding-bottom: 44px;
  }
  .btn {
    border-radius: 8px;
    height: 40px;
    @media ${device.desktopLG} {
      min-width: 418px;
    }
  }
`

export const Card = styled.div`
  background: ${grayscale[900]};
  min-height: 230px;
  padding-top: 24px;
  padding-bottom: 20px;
  padding-right: 18px;
  padding-left: 20px;
  border-radius: 8px;

  .margin-bottom {
    margin-bottom: 36px;
  }

  svg {
    width: 32px;
    height: 32px;
  }
`

export const ContainerCarousel = styled.div`

`
