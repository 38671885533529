import React from 'react'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { orange } from 'src/styles/colors'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { Card, ContainerCarousel, SectionConhecaOsBeneficiosMastercardBlack } from './style'

import dataJson from './assets/data/data.json'
import { IOpenModalComponent } from '../../types'

type CardType = {
  icon: string;
  title: string;
  description: string;
}
export const ConhecaOsBeneficiosMastercardBlack = ({ openModal, oneLink, setDataLayer }: IOpenModalComponent) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dobra04: IDataLayerParams = {
    section: 'dobra_04',
    section_name: 'Conheça os benefícios do Mastercard Black',
    element_action: 'click button',
    element_name: 'Solicite seu cartão Black Inter',
  }

  const cardsData: CardType[] = dataJson
  return (
    <SectionConhecaOsBeneficiosMastercardBlack
      id='conheca-os-beneficios'
    >
      <div className='container pt-4 pt-md-0'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12 col-lg-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-white mb-3 mb-md-4'>
              Conheça os <span className='d-xl-block'>benefícios do</span> Mastercard Black
            </h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-white mb-0 mb-md-5'>
              Aproveite o acesso a serviços especiais e as vantagens que só quem viaja com o Cartão Inter Mastercard Black pode experimentar.
            </p>
            <div className='d-none d-lg-block'>
              <button
                title='Solicite seu cartão Black Inter'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-none d-md-block'
                onClick={() => {
                  openModal && openModal()
                  setDataLayer(dobra04)
                  sendDatalayerEvent(dobra04)
                }}
              >
                Solicite seu cartão Black Inter
              </button>
              <a
                title='Solicite seu cartão Black Inter'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-md-none'
                href={oneLink}
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    section_name: 'Conheça os benefícios do Mastercard Black',
                    element_action: 'click button',
                    element_name: 'Solicite seu cartão Black Inter',
                    redirect_url: oneLink,
                  })
                }}
              >
                Solicite seu cartão Black Inter
              </a>
            </div>
          </div>
          <div className='col-12 col-lg-6 offset-xl-1'>
            <ContainerCarousel>
              <DefaultCarousel
                xl={{ items: 2, partialVisibilityGutter: 72 }}
                lg={{ items: 2, partialVisibilityGutter: 24 }}
                md={{ items: 3, partialVisibilityGutter: 28 }}
                sm={{ items: 1, partialVisibilityGutter: 110 }}
              >
                {cardsData.map((card: CardType) => (
                  <Card key={card.icon} className='mr-4'>
                    <div className='margin-bottom'>
                      <div>
                        <OrangeIcon icon={card.icon} color={orange.extra} size='MD' />
                      </div>
                    </div>
                    <div>
                      <h4 className='fs-16 lh-20 fw-600 text-white mb-2'>{card.title}</h4>
                      <p className='fs-14 lh-17 fw-400 text-grayscale--300 mb-0'>{card.description}</p>
                    </div>
                  </Card>
              ))}
              </DefaultCarousel>
            </ContainerCarousel>
          </div>
        </div>
      </div>
    </SectionConhecaOsBeneficiosMastercardBlack>
  )
}

export default ConhecaOsBeneficiosMastercardBlack
